import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BImg,
  Card,
  Dropdown,
  HeadC2L,
  Hori_Divider,
  Tag,
} from "../components/utilityComps.js";
import { useAppContext } from "../App.js";
import {
  filt_ar_using_filters,
  filt_ar_using_valfilt,
  gen_filters_cleared,
  gen_filters_from_valob,
  gen_valob_from_filters,
  mainfiltbtn,
} from "../utils/filt.js";
import {
  base64_to_json,
  dec,
  emp,
  getv,
  iso,
  jparse,
  json_to_base64,
  jstr,
  nano,
  nils,
} from "../utils/utils.js";
import {
  F_OptionBtn_R2_rounded,
  F_OptionInput,
  F_OptionInput_Raw,
  InpAutoWrap,
  set_state_ob,
  CapsuleSwitch,
} from "../components/input.js";
import {
  class_cn,
  class_text,
  get_cprofile_hex,
  get_payout_cn,
  get_payout_txt,
} from "../utils/cn_map.js";
import { useQueries } from "react-query";
import {
  q_open_minis,
  q_open_races,
  q_races_options,
  q_token_prices,
  q_vaultbikes,
  q_vaultraces,
  q_autofiller_active_bike_summary,
  qiserr,
  qissuccesss,
  q_races,
} from "../queries/queries.js";
import {
  RaceCard,
  RaceRow_Rounds_side,
  RaceRow_side,
  StableHRow,
  get_race_from_mini,
} from "../utils/raceutils.js";
import _, { parseInt } from "lodash";
import { Loader01c } from "../components/anims.js";
import { twMerge } from "tailwind-merge";
import {
  SavedSettingPresetsSection,
  useAccountContext,
} from "../wrappers/AccountWrapper.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClose,
  faFilter,
  faFire,
  faFlagCheckered,
  faHelmetSafety,
  faHelmetUn,
  faRefresh,
  faSatelliteDish,
  faSdCard,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { InfoBanner } from "../components/Layout.js";
import {
  FiltCard,
  GlowBox,
  GlowBtnGroup,
  RaceCardR2_full,
  RaceCardR2_mini,
  RacesMidBreakBanner,
  SkewBtn,
  svg_map,
} from "../utils/raceutils2.js";
import { Link } from "react-router-dom";
import { FactionsWrapper, useFactionContext } from "./Factions/Factions.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";
import { AgentMissionsCard } from "./Factions/FactionMissions.js";
import { RVImg } from "../components/BikeImg.js";
import { RoadmapBackLink } from "./Roadmap.js";
import { ErrorBoundary } from "../utils/errbou.js";
import { RaceCard_cv2_full, RaceCard_cv2_mini } from "../utils/raceutils3.js";

export const RacesContext = createContext();
export const useRacesContext = () => useContext(RacesContext);

export const racecn = {
  mainfiltbtn:
    "transition duration-500 resp-text--2 font-digi font-semibold  text-center mb-3 ",
};

const rvmode_s = ["car", "bike", "horse"];

const FeeFilters = () => {
  const rcon = useRacesContext();
  const { filt, set_filt, clear_filt, q_open_minis_enabled } = rcon;
  const inpargs = {
    fkey: "open",
    filters: filt,
    set_filters: set_filt,
  };
  const fee_mode = getv(filt, "fee_mode.vals");

  return (
    <>
      <div className="fr-cc w-max bg-dark mx-auto rounded-md  mb-2">
        {[
          ["ALL", "bg-acc0/40"],
          ["Free", "bg-yellow-400/70"],
          ["Paid", "bg-green-400/70"],
        ].map(([txt, cn]) => {
          return (
            <Tag
              onClick={() => set_state_ob(filt, set_filt, "fee_mode.vals", txt)}
              className={twMerge(
                "rounded-md resp-px-4 resp-py-2 m-0",
                fee_mode == txt ? cn : "",
              )}
            >
              {txt}
            </Tag>
          );
        })}
      </div>
      {fee_mode == "Paid" && (
        <>
          <div className="mt-4 fr-cs w-max mx-auto resp-gap-2">
            <div className="w-max mx-auto">
              <InpAutoWrap {...{ ...inpargs, idd: "fee_usd" }} />
            </div>
            <Tag
              onClick={() => {
                set_state_ob(filt, set_filt, "fee_usd.vals", {
                  mi: null,
                  mx: null,
                });
              }}
              className="text-red-400 rounded-[100px] border border-red-400 resp-px-2 text-[10px]"
            >
              clear
            </Tag>
          </div>
        </>
      )}
    </>
  );
};

export const RacesFiltSection = ({ useContext = useRacesContext }) => {
  const rcon = useContext();
  const {
    filt,
    set_filt,
    clear_filt,
    q_open_minis_enabled,

    peek,
    set_peek,
    toburn,
    set_toburn,
    beta,
    set_beta,
    giveeacards,
    set_giveeacards,
    showtpraces,
    set_showtpraces,
    c,
    set_c,
  } = rcon;

  const [q_ops] = useQueries([q_races_options({ class: [c] })]);
  const ops = useMemo(
    () => getv(q_ops, "data.result") ?? {},
    [q_ops.dataUpdatedAt],
  );

  const filtmap = {
    order: [
      // "rvmode",
      "cb",
      "rgate",
      "payout",
      "format",
      "eventtags",
    ],
    filt: _.chain([
      [
        "rvmode",
        "RV_Mode",
        "options",
        "rvmode",
        ["car", "bike", "horse"],
        (o) => _.capitalize(o),
      ],
      ["cb", "Distance", "options", "cb", ops.cb, (o) => `CB${o}00`],
      ["rgate", "Gates", "options", "rgate", ops.rgate, (o) => `${o} Gates`],
      [
        "payout",
        "Payout",
        "options",
        "payout",
        ops.payout,
        (o) => get_payout_txt(o),
      ],
      [
        "format",
        "Format",
        "options",
        "format",
        ops.format,
        (o) => o && o.toUpperCase(),
      ],
      [
        "eventtags",
        "Events",
        "options-and",
        "eventtags",
        ops.eventtags,
        (o) => o,
      ],
    ])
      .map((e) => {
        let [idd, label, type, path, options, txt_fn] = e;
        return {
          idd,
          label,
          type,
          path,
          options,
          txt_fn,
        };
      })
      .keyBy("idd")
      .value(),
  };

  const c4 = get_cprofile_hex("acc4");
  const dark = get_cprofile_hex("r2dark");

  return (
    <>
      <GlowBtnGroup
        {...{
          options: [50, 60, 70, 80, 90],
          selected: c,
          opmap: _.chain([50, 60, 70, 80, 90])
            .map((c) => {
              let o = { text: class_text(c, "T"), svg: svg_map[`c${c}`] };
              o.extcn = `class-select-c${c}`;
              return [c, o];
            })
            .fromPairs()
            .value(),
          setter: set_c,
        }}
      />
      <FiltCard
        {...{
          nfkey: "open",
          nf: filt,
          set_nf: set_filt,
          filtmap,
          top_jsx: (
            <div className="fc-cc my-2 resp-gap-2">
              <Link to={`/quest-enter`}>
                <Tag className="-skew-x-12  bg-acc4/30">
                  <span className="font-digi resp-text-0">
                    Create a Quest Race
                  </span>
                </Tag>
              </Link>
              <div class="fr-sc resp-gap-2">
                {rvmode_s.map((rv) => {
                  let active = _.includes(filt.rvmode, rv);
                  return (
                    <div
                      onClick={() => {
                        // let nrvs = _.cloneDeep(filt.rvmode) || [];
                        // if (active) nrvs = _.without(nrvs, rv);
                        // else nrvs.push(rv);
                        // set_state_ob(filt, set_filt, "rvmode", nrvs);
                        // let
                        let nrvs = active ? [] : [rv];
                        set_state_ob(filt, set_filt, "rvmode", nrvs);
                      }}
                      className={twMerge(
                        "cursor-pointer resp-p-2 h-full rounded-md",
                        active
                          ? "bg-acc4 shadow-acc0 shadow-md"
                          : "bg-r2dark/20",
                      )}
                    >
                      <RVImg
                        {...{ rvmode: rv }}
                        hex_code={!active ? c4 : "#FFFFFF"}
                        className={"xs:w-[4rem] lg:w-[8rem]"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ),
          bottom_jsx: (
            <>
              <div className="mx-auto w-max max-w-[100%] grid grid-cols-4 gap-1 flex-wrap mt-4 my-2">
                <Tag
                  onClick={() => {
                    set_toburn(false);
                    set_peek(null);
                    set_giveeacards(false);
                    // set_showtpraces(false);
                    set_showtpraces((a) => !(a === true));
                  }}
                  className={twMerge(
                    "border border-acc4 resp-px-4",
                    showtpraces === true
                      ? "bg-acc4 text-white"
                      : "bg-acc4/20 text-white",
                  )}
                >
                  <div className="fc-sc gap-1 resp-text--1 ">
                    <span className="resp-text-1 font-digi">Trail Points</span>
                  </div>
                </Tag>

                <Tag
                  onClick={() => {
                    set_toburn(false);
                    set_peek(null);
                    // set_giveeacards(false);
                    set_showtpraces(false);
                    set_beta(false);
                    set_giveeacards((a) => !(a === true));
                  }}
                  className={twMerge(
                    "border border-acc4 resp-px-4",
                    giveeacards === true
                      ? "bg-acc4 text-white"
                      : "bg-acc4/20 text-white",
                  )}
                >
                  <div className="fr-cc gap-1 resp-text--1 ">
                    <span className="resp-text-1 font-digi">Cards</span>
                  </div>
                </Tag>

                <Tag
                  onClick={() => {
                    // set_toburn(false);
                    set_peek(null);
                    set_giveeacards(false);
                    set_showtpraces(false);
                    set_beta(false);
                    set_toburn((a) => !(a === true));
                  }}
                  className={twMerge(
                    "border border-acc4 resp-px-4",
                    toburn === true
                      ? "bg-acc4 text-white"
                      : "bg-acc4/20 text-white",
                  )}
                >
                  <div className="fr-cc gap-1 resp-text--1 ">
                    <span className="resp-text-1 font-digi">Burn</span>
                  </div>
                </Tag>

                <Tag
                  onClick={() => {
                    set_toburn(false);
                    set_peek(null);
                    set_giveeacards(false);
                    set_showtpraces(false);
                    // set_beta(false);
                    set_beta((a) => !(a === true));
                  }}
                  className={twMerge(
                    "border border-yellow-400 resp-px-4",
                    beta === true
                      ? "bg-yellow-500 text-white"
                      : "bg-acc4/20 text-white",
                  )}
                >
                  <div className="fr-cc gap-1 resp-text--1 ">
                    <span className="resp-text-1 font-digi">BETA</span>
                  </div>
                </Tag>
              </div>
              <div className="fr-sc flex-wrap mt-4 my-2">
                <div class="flex-1"></div>

                <CapsuleSwitch
                  {...{
                    idd: "noextcri",
                    options: ["noextcri", null],
                    nf: peek,
                    set_nf: set_peek,
                    txt_fn: (o) => (o == "noextcri" ? "ON" : "OFF"),
                    label: "No Entry Criteria",
                    show_label: true,
                    path: null,
                  }}
                />
              </div>
            </>
          ),
        }}
      />
    </>
  );
};

export const SMFiltSection = ({ show, open, close }) => {
  const { s_acc_config, g_acc_config } = useAccountContext();
  const rcon = useRacesContext();
  const {
    filt,
    set_filt,
    clear_filt,
    q_open_minis_enabled,
    c,
    set_c,
    peek,
    set_peek,
    toburn,
    set_toburn,
    giveeacards,
    set_giveeacards,
    showtpraces,
    set_showtpraces,
  } = rcon;
  const inpargs = {
    fkey: "open",
    filters: filt,
    set_filters: set_filt,
  };

  const [expand, set_expand] = useState(g_acc_config("racessmfilt.expand", {}));
  useEffect(() => {
    s_acc_config("racessmfilt.expand", expand);
  }, [expand]);

  const [q_ops] = useQueries([q_races_options({ class: [c] })]);
  const ops = useMemo(
    () => getv(q_ops, "data.result") ?? {},
    [q_ops.dataUpdatedAt],
  );

  const filtmap = {
    order: ["cb", "rgate", "payout", "format", "eventtags"],
    filt: _.chain([
      ["cb", "Distance", "options", "cb", ops.cb, (o) => `CB${o}00`],
      ["rgate", "Gates", "options", "rgate", ops.rgate, (o) => `${o} Gates`],
      [
        "payout",
        "Payout",
        "options",
        "payout",
        ops.payout,
        (o) => get_payout_txt(o),
      ],
      [
        "format",
        "Format",
        "options",
        "format",
        ops.format,
        (o) => o && o.toUpperCase(),
      ],
      ["eventtags", "Events", "options", "eventtags", ops.eventtags, (o) => o],
    ])
      .map((e) => {
        let [idd, label, type, path, options, txt_fn] = e;
        return {
          idd,
          label,
          type,
          path,
          options,
          txt_fn,
        };
      })
      .keyBy("idd")
      .value(),
  };

  if (!show) return <></>;

  return (
    <div
      className={
        "h-full bg-r2dark fixed top-[0] left-0 w-full z-40 overflow-auto"
      }
    >
      <div className="h-page">
        <GlowBtnGroup
          {...{
            options: [50, 60, 70, 80, 90],
            selected: c,
            opmap: _.chain([50, 60, 70, 80, 90])
              .map((c) => {
                let o = { text: class_text(c, "T"), svg: svg_map[`c${c}`] };
                o.extcn = `sm-class-select-c${c}`;
                return [c, o];
              })
              .fromPairs()
              .value(),
            setter: set_c,
          }}
        />

        <FiltCard
          {...{
            nfkey: "open",
            nf: filt,
            set_nf: set_filt,
            filtmap,

            top_jsx: (
              <div className="fc-cc my-2 resp-gap-2">
                <Link to={`/quest-enter`}>
                  <Tag className="-skew-x-12  bg-acc4/30">
                    <span className="font-digi resp-text-0">
                      Create a Quest Race
                    </span>
                  </Tag>
                </Link>
                <div class="fr-sc resp-gap-2">
                  {rvmode_s.map((rv) => {
                    let active = _.includes(filt.rvmode, rv);
                    return (
                      <div
                        onClick={() => {
                          // let nrvs = _.cloneDeep(filt.rvmode) || [];
                          // if (active) nrvs = _.without(nrvs, rv);
                          // else nrvs.push(rv);
                          // set_state_ob(filt, set_filt, "rvmode", nrvs);
                          // let
                          let nrvs = active ? [] : [rv];
                          set_state_ob(filt, set_filt, "rvmode", nrvs);
                        }}
                        className={twMerge(
                          "cursor-pointer resp-p-2 h-full rounded-md",
                          active
                            ? "bg-r2dark/40 shadow-acc4 shadow-md"
                            : "bg-r2dark/40",
                        )}
                      >
                        <RVImg
                          {...{ rvmode: rv }}
                          hex_code={active ? "09D3FF" : "FFFFFF"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ),
            bottom_jsx: (
              <>
                <div className="mx-auto w-max max-w-[100%] grid grid-cols-3 flex-wrap mt-4 my-2">
                  <Tag
                    onClick={() => {
                      set_toburn(false);
                      set_peek(null);
                      set_giveeacards(false);
                      // set_showtpraces(false);
                      set_showtpraces((a) => !(a === true));
                    }}
                    className={twMerge(
                      "border border-acc4 resp-px-4",
                      showtpraces === true
                        ? "bg-acc4 text-white"
                        : "bg-acc4/20 text-white",
                    )}
                  >
                    <div className="fc-sc gap-1 resp-text--1 ">
                      <span className="resp-text-1 font-digi">
                        Trail Points
                      </span>
                    </div>
                  </Tag>

                  <Tag
                    onClick={() => {
                      set_toburn(false);
                      set_peek(null);
                      // set_giveeacards(false);
                      set_showtpraces(false);
                      set_giveeacards((a) => !(a === true));
                    }}
                    className={twMerge(
                      "border border-acc4 resp-px-4",
                      giveeacards === true
                        ? "bg-acc4 text-white"
                        : "bg-acc4/20 text-white",
                    )}
                  >
                    <div className="fr-cc gap-1 resp-text--1 ">
                      <span className="resp-text-1 font-digi">Cards</span>
                    </div>
                  </Tag>

                  <Tag
                    onClick={() => {
                      // set_toburn(false);
                      set_peek(null);
                      set_giveeacards(false);
                      set_showtpraces(false);
                      set_toburn((a) => !(a === true));
                    }}
                    className={twMerge(
                      "border border-acc4 resp-px-4",
                      toburn === true
                        ? "bg-acc4 text-white"
                        : "bg-acc4/20 text-white",
                    )}
                  >
                    <div className="fr-cc gap-1 resp-text--1 ">
                      <span className="resp-text-1 font-digi">Burn</span>
                    </div>
                  </Tag>
                </div>
                <div className="fr-sc flex-wrap mt-4 my-2">
                  <div class="flex-1"></div>

                  <CapsuleSwitch
                    {...{
                      idd: "noextcri",
                      options: ["noextcri", null],
                      nf: peek,
                      set_nf: set_peek,
                      txt_fn: (o) => (o == "noextcri" ? "ON" : "OFF"),
                      label: "No Entry Criteria",
                      show_label: true,
                      path: null,
                    }}
                  />
                </div>
              </>
            ),
          }}
        />
      </div>

      <SMCloseBtn {...{ closefn: close, extcn: "sm-rsnav-close-filters" }} />
    </div>
  );
};

export const SMCloseBtn = ({ closefn = () => {}, extcn = "" }) => {
  return (
    <div
      className={twMerge(
        extcn,
        "fixed bottom-[3rem] z-[10]",
        "w-max left-[50%] transform translate-x-[-50%]",
      )}
      onClick={closefn}
    >
      <Tag className="fr-sc resp-gap-1 bg-r2lig xs:px-[1rem] xs:py-[0.5rem] rounded-full">
        <FontAwesomeIcon className="resp-text-3" icon={faClose} />
        <span className="resp-text-1">Close</span>
      </Tag>
    </div>
  );
};

export const SMMyRaces = ({ show, close }) => {
  if (!show) return <></>;

  return (
    <Card className={"h-full bg-r2dark fixed top-[0] left-0 w-full z-40"}>
      <div className="fr-cc w-full">
        <p>Vault Races</p>
        <div className="flex-1"></div>
        <Tag onClick={() => close()} className="">
          <FontAwesomeIcon className="resp-text-2" icon={faClose} />
        </Tag>
      </div>
      <div className="h-page">
        <LeftSidebar />
      </div>
      <SMCloseBtn {...{ closefn: close, extcn: "sm-rsnav-close-myraces" }} />
    </Card>
  );
};

export const SMMyBikes = ({ show, close }) => {
  if (!show) return <></>;
  return (
    <Card className={"h-full bg-r2dark fixed top-[0] left-0 w-full z-40"}>
      <div className="fr-cc w-full">
        <div className="flex-1"></div>
        <Tag onClick={() => close()} className="">
          <FontAwesomeIcon className="resp-text-2" icon={faClose} />
        </Tag>
      </div>
      <div className="h-page">
        <RightSidebar />
      </div>
      <SMCloseBtn {...{ closefn: close }} />
    </Card>
  );
};

export const LeftSidebar = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val, usd_to_tok_val } = appcon;

  const aucon = useAuthContext();
  const { auth, vault } = aucon;
  useEffect(() => {
    console.log("aucon", { auth, vault });
  }, [auth, vault]);

  const accon = useAccountContext();
  const { g_acc_config, s_acc_config } = accon;

  const [qo_vaultraces] = useQueries([
    q_vaultraces({ vault, auth }, { enabled: !nils(vault) && auth === true }),
    ,
  ]);
  // useEffect(() => { console.log("qo_vaultraces", qo_vaultraces); }, [q_vaultraces]);
  const racesob = useMemo(() => {
    let now = iso();
    let rs = getv(qo_vaultraces, "data.result");
    rs = _.chain(rs)
      .map((r) => {
        // console.log(r.format, r.fee, r.paytoken);
        r.fee_usd = tok_to_usd_val(r.fee, r.paytoken);
        r.prize_usd = tok_to_usd_val(r.prize, r.paytoken);
        return r;
      })
      .value();

    if (nils(rs))
      return {
        open: [],
        scheduled: [],
        finished: [],
      };

    rs = (rs || []).map((r) => {
      let r2 = get_race_from_mini([
        r.rid,
        r.mini,
        r.format,
        r.format_inf,
        r.race_name,
      ]);
      // if (nils(r2?.cb)) console.log(r, r2);
      r = { ...r2, ...r };
      return r;
    });
    rs = _.groupBy(rs, "status");

    if (nils(rs.open)) rs.open = [];
    if (nils(rs.scheduled)) rs.scheduled = [];
    if (nils(rs.finished)) rs.finished = [];

    return rs;
  }, [qo_vaultraces.dataUpdatedAt]);

  const headcn = "resp-text-0 font-digi text-slate-100";

  const [statusshow, set_statusshow] = useState(
    g_acc_config("leftsidebar.statusshow", {
      open: true,
      scheduled: true,
      finished: true,
    }),
  );
  useEffect(() => {
    s_acc_config("leftsidebar.statusshow", statusshow);
  }, [statusshow]);

  return (
    <>
      <div
        className={twMerge(
          "recent-vault-races",
          "rounded-md h-full max-h-full overflow-auto resp-text--2",
        )}
      >
        <div className="fr-sc resp-p-2 resp-gap-1">
          <div className="flex-1">
            {qiserr(qo_vaultraces) && (
              <p className="text-center text-red-300 border border-red-300 resp-text--2">
                {qiserr(qo_vaultraces)}
              </p>
            )}
          </div>
          <Tag
            className={twMerge(
              "resp-text--1 text-acc0",
              qo_vaultraces.isLoading ? "spin-anim" : "",
            )}
            onClick={qo_vaultraces.refetch}
          >
            <FontAwesomeIcon icon={faRefresh} />
          </Tag>
        </div>

        {[
          "open",
          "scheduled",
          // "rounds",
          "finished",
        ].map((status) => {
          let rs = racesob[status];
          let act = statusshow[status];

          return (
            <div
              className={twMerge(
                `vault-races-${status}-section`,
                act ? "is_open" : "is_closed",
                "w-full border border-acc4 transition duration-200 rounded-lg my-2 p-1",
                act ? "bg-r2dark/20" : "bg-r2lig/20",
              )}
            >
              <div
                onClick={() => {
                  set_statusshow({ ...statusshow, [status]: !act });
                }}
                className={twMerge(
                  "toggler",
                  "fr-sc w-full cursor-pointer p-2 mr-1",
                )}
              >
                <span className={headcn}>{_.capitalize(status)} Races</span>
                <div className="flex-1"></div>
                {qo_vaultraces.isLoading ? <Loader01c size="s" /> : <></>}
                {act ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
              </div>
              {qo_vaultraces.isSuccess && act && (
                <div className="my-[0.5rem]">
                  {_.isEmpty(rs) ? (
                    <p className="text-center">No Races</p>
                  ) : (
                    rs.map((race, ridx) => {
                      if (status == "rounds")
                        return (
                          <RaceRow_Rounds_side {...{ race, key: race.rid }} />
                        );
                      else
                        return (
                          <div class={`vault-race-${status}-${ridx}`}>
                            <RaceRow_side {...{ race, key: race.rid }} />
                          </div>
                        );
                    })
                  )}
                </div>
              )}
              {/* <motion.div
                variants={MoVariants.show_hide}
                animate={statusshow[status] ? "visible" : "hidden"}
                initial={statusshow[status] ? "visible" : "hidden"}
              > */}
              {/*
               */}
              {/* </motion.div> */}
            </div>
          );
        })}
      </div>
    </>
  );
};

const LgBikesList = ({ autoob }) => {
  const rcon = useRacesContext();
  const accon = useAccountContext();
  const { auth } = useAuthContext();

  const { hids = [], bikesob = {} } = accon;
  const { acc_config = {}, s_acc_config } = accon;
  const { selrace, selrace_dets, valfilt } = rcon;
  // useEffect(() => { console.log("acc_config", acc_config); }, [jstr(acc_config)]);

  const [is_loading1, is_loading2] = useMemo(() => {
    let load1 = accon.qo_vaultinfo.isLoading || accon.qo_vaultbikes.isLoading;
    let load2 = accon.qst_bdocs.loading;
    return [load1, load2];
  }, [accon]);

  const [c, cbs] = useMemo(() => {
    if (selrace && selrace_dets) return [selrace_dets.class, [selrace_dets.cb]];
    else return [getv(valfilt, "class.0"), getv(valfilt, "cb")];
  }, [valfilt, selrace_dets]);
  // console.log("sel", { c, cbs });
  const valfilt_cb = valfilt.cb;

  const [rvmode, set_rvmode] = useState(
    !nils(getv(acc_config, "last_rvmode"))
      ? getv(acc_config, "last_rvmode")
      : "bike",
  );
  useEffect(() => {
    let cac_rvmode = getv(acc_config, "last_rvmode");
    if (!nils(cac_rvmode) && cac_rvmode !== rvmode) {
      set_rvmode(cac_rvmode);
    }
  }, [getv(acc_config, "last_rvmode"), auth]);
  useEffect(() => {
    if (!auth) return;
    s_acc_config("last_rvmode", rvmode);
  }, [rvmode]);

  const [mode, set_mode] = useState(
    !nils(getv(acc_config, "last_mode"))
      ? getv(acc_config, "last_mode")
      : "all",
  );

  useEffect(() => {
    let cac_mode = getv(acc_config, "last_mode");
    if (!nils(cac_mode) && cac_mode !== mode) {
      set_mode(cac_mode);
    }
  }, [getv(acc_config, "last_mode"), auth]);
  useEffect(() => {
    if (!auth) return;
    s_acc_config("last_mode", mode);
  }, [mode]);

  useEffect(() => {}, [jstr(mode), auth]);

  const filthids_show = useMemo(() => {
    let hasccb = !_.isEmpty(cbs);
    let hs = _.chain(hids)
      .map((hid) => {
        let h = bikesob[hid];
        if (nils(h)) return null;

        const hconf = getv(acc_config, `vaulthconfs.${rvmode}.${hid}`) || {};

        let nothide = hconf?.hide !== true;
        let cbcmatch = [50, 60, 70, 80, 90].includes(c)
          ? true
          : hasccb
            ? _.intersection(valfilt_cb, cbs).length > 0
            : // ? valfilt_cb.includes(cb)
              true;

        let notcbchide = !hasccb
          ? true
          : _.isEmpty(hconf?.cbmap)
            ? true
            : _.intersection(hconf.cbmap, cbs).length > 0;
        // : hconf.cbmap.includes(cb);

        let show = 10;
        if (show == 10 && !nothide) show = 0;
        if (show == 10 && !notcbchide) show = 2;
        let resp = [
          hid,
          show,
          cbcmatch,
          { nothide, cbcmatch, notcbchide },
          hconf,
        ];
        // if (hid == 10) {
        //   console.log(hid, resp);
        // }
        return resp;
      })
      .compact()
      .filter((h) => {
        if (hasccb) return h[2];
        else return true;
      })
      .filter((h) => {
        if (mode == "filtered") return h[1] == 10;
        else return true;
      })
      .value();
    return hs;
  }, [
    mode,
    hids,
    c,
    cbs,
    jstr(_.keys(bikesob)),
    acc_config?.vaulthconfs,
    rvmode,
  ]);

  const c4 = get_cprofile_hex("acc4");
  const dark = get_cprofile_hex("r2dark");

  const [show_prests, set_show_prests] = useState(false);

  return (
    <div className="relative">
      <div className="w-full fc-cc p-2 resp-gap-2 bg-r2reg/40 backdrop-blur-md sticky top-0 z-10">
        <div class=" fr-sc">
          {rvmode_s.map((rv) => {
            let active = rvmode == rv;
            return (
              <div
                onClick={() => {
                  if (show_prests) set_show_prests(false);
                  set_rvmode(rv);
                }}
                className={twMerge(
                  "cursor-pointer resp-p-1 h-full rounded-md",
                  active ? "bg-acc4 shadow-acc0 shadow-md" : "bg-r2dark/20",
                )}
              >
                <RVImg
                  {...{ rvmode: rv }}
                  hex_code={!active ? c4 : "#FFFFFF"}
                  className={"xs:w-[1.5rem] lg:w-[3rem]"}
                />
              </div>
            );
          })}
        </div>
        <div class="w-full fr-sc">
          <div className="w-max fr-sc bg-dark rounded-full overflow-hidden cursor-pointer">
            {[
              ["all", "All", "text-acc0 text-bold", "bg-r2lig text-white"],
              [
                "filtered",
                "Filtered",
                "text-acc0 text-bold",
                "bg-r2lig text-white",
              ],
            ].map(([k, txt, cn, acn]) => {
              return (
                <span
                  onClick={() => {
                    if (show_prests) set_show_prests(false);
                    set_mode(k);
                  }}
                  className={twMerge(
                    "resp-text--1",
                    "resp-px-1 resp-py-2 m-0 transition duration-200 w-[5rem] text-center",
                    cn,
                    mode == k && acn,
                  )}
                >
                  {txt}
                </span>
              );
            })}
          </div>
          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              set_show_prests(!show_prests);
            }}
            className={twMerge(
              "fr-sc resp-gap-1 text-acc4 border border-acc4",
              show_prests == true ? "bg-acc4 text-white" : "",
            )}
          >
            <span class="resp-text-0">PRESETS</span>
          </Tag>
        </div>
      </div>
      {nils(rvmode) ? null : (
        <>
          <div className="resp-p-2">
            {show_prests ? (
              <>
                <SavedSettingPresetsSection />
              </>
            ) : (
              <>
                {is_loading1 && (
                  <div className="fr-cc resp-my-1 resp-gap-2 resp-text-1 font-mon resp-px-4 text-acc0">
                    <div className="flex-1"></div>
                    <Loader01c size="s" />
                    <span>Loading Vault</span>
                  </div>
                )}
                {is_loading2 && (
                  <div className="fr-cc resp-my-1 resp-gap-2 resp-text-1 font-mon resp-px-4 text-acc4">
                    <div className="flex-1"></div>
                    <Loader01c size="s" />
                    <span>Loading Bikes</span>
                  </div>
                )}
                {_.isEmpty(filthids_show) && is_loading2 == false && (
                  <p className="text-acc0">No Bikes</p>
                )}
                {(filthids_show || []).map(([hid, show]) => {
                  let h = bikesob[hid];
                  if (nils(h)) return <></>;
                  let auto = autoob[hid];
                  h.auto_rem = getv(auto, "rem");
                  return <StableHRow {...{ key: hid, hid, h, rvmode }} />;
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const LgMissionsList = () => {
  const { auth, vault } = useAuthContext();
  const fcon = useFactionContext();
  const { agids, agentsob, missionsob } = fcon;

  return (
    <div class="p-1">
      {agids.map((agid) => {
        const a = agentsob[agid];
        const m = missionsob[agid];
        const qa = getv(a, "q");
        const qm = getv(missionsob[agid], "q");

        if (nils(a)) return;
        if (_.isEmpty(m)) return;

        return <AgentMissionsCard {...{ side_view: true, a, qa, qm }} />;
      })}
    </div>
  );
};

const RightSidebar = () => {
  const seltabkey = "lobby_rightsidebar_tab";
  const [tab, set_tab] = useState(
    localStorage.getItem(seltabkey) ?? "bikes_list",
  );
  useEffect(() => {
    localStorage.setItem(seltabkey, tab);
  }, [tab]);

  const { auth, vault } = useAuthContext();
  const [qoautobikes] = useQueries([
    q_autofiller_active_bike_summary(
      {
        vault,
      },
      {
        enabled: !nils(vault) && tab == "bikes_list",
      },
    ),
  ]);
  const autoob = useMemo(() => {
    return getv(qoautobikes, "data.result", {});
  }, [qoautobikes.dataUpdatedAt]);

  const [tab_base_cn, tab_active_cn] = [
    "text-center px-4 text-acc4 border border-acc4 font-digi -skew-x-12",
    "bg-acc4/60 border border-acc4/60 text-white ",
  ];

  return (
    <>
      <div className="bg-dark/20 border border-acc4 rounded-md h-full max-h-full overflow-auto">
        <div class="flex flex-row justify-around items-center mt-4 mb-2">
          {[
            ["bikes_list", "Bikes List"],
            ["missions", "Missions"],
          ].map(([k, txt]) => {
            return (
              <Tag
                className={twMerge(tab_base_cn, tab == k ? tab_active_cn : "")}
                onClick={() => set_tab(k)}
              >
                {txt}
              </Tag>
            );
          })}
        </div>
        {tab == "bikes_list" && <LgBikesList {...{ autoob }} />}
        {tab == "missions" && (
          <FactionsWrapper vault={vault}>
            <LgMissionsList />
          </FactionsWrapper>
        )}
        <div class="h-[10rem]"></div>
      </div>
    </>
  );
};

export const RacesListInner = ({
  races = [],
  qo_races,
  selrace,
  set_selrace,
  post_enterrace,
}) => {
  const { s_acc_config } = useAccountContext();

  return (
    <>
      {(races || []).map((race, idx) => {
        race.fetch_time = iso(qo_races.dataUpdatedAt);
        let extcn = `rs-filt-${idx}`;
        return (
          <div className={twMerge(extcn)}>
            {race.rid == selrace ? (
              <div class="relative ">
                <div class="absolute z-[10] top-[2px] right-[2px]">
                  <div
                    onClick={() => {
                      set_selrace(undefined);
                    }}
                    class="resp-p-2 text-red-400 resp-text-1 cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
                {race?.version == "cv2" ? (
                  <RaceCard_cv2_full {...{ race, post_enterrace }} />
                ) : (
                  <RaceCardR2_full {...{ race, post_enterrace }} />
                )}
              </div>
            ) : (
              <div
                onClick={() => {
                  // set_selrace(race.rid);
                  let redid = "last_rvmode";
                  s_acc_config(redid, race.rvmode);
                }}
                class="w-full cursor-pointer"
              >
                {race?.version == "cv2" ? (
                  <RaceCard_cv2_mini
                    {...{ race, selrace, set_selrace, post_enterrace }}
                  />
                ) : (
                  <RaceCardR2_mini
                    {...{ race, selrace, set_selrace, post_enterrace }}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export const RacesListWrapped = ({ rids }) => {
  const [qo_races] = useQueries([
    q_races({ rids }, { enabled: !_.isEmpty(rids) }),
  ]);
  const [selrace, set_selrace] = useState(null);
  const races = useMemo(() => {
    let races = getv(qo_races.data, "result");
    return races;
  }, [qo_races.dataUpdatedAt]);

  const races_context = {
    races,
    qo_races,
    selrace,
    set_selrace,
    post_enterrace: () => {},
  };

  return <RacesListInner {...races_context} />;
};

export const RacesList = ({ useContext = useRacesContext }) => {
  const {
    qo_open_minis,
    filtraces,
    races_n,
    q_open_minis_enabled,

    selrace,
    set_selrace,
    post_enterrace,
  } = useContext();

  return (
    <RacesListInner
      {...{
        races: filtraces,
        qo_races: qo_open_minis,
        selrace,
        set_selrace,
        post_enterrace,
      }}
    />
  );
};

export const RacesSection = ({ useContext = useRacesContext }) => {
  const rcon = useContext();
  const { q_open_minis_enabled, qo_open_minis, filtraces_n } = rcon;

  return (
    <>
      {q_open_minis_enabled == false ? (
        <>
          <p className="text-center text-yellow-300 font-digi resp-text-1 my-2">
            Please Select Some Type and Vehicle to view Races
          </p>
        </>
      ) : (
        <>
          {qo_open_minis.isLoading ? (
            <>
              <Loader01c />
            </>
          ) : (
            <>
              {qiserr(qo_open_minis) && (
                <p className="text-center text-red-300 resp-text-1">
                  {qiserr(qo_open_minis)}
                </p>
              )}
              {qissuccesss(qo_open_minis) && (
                <>
                  {filtraces_n == 0 ? (
                    <p className="text-center text-acc0 font-digi resp-text-3">
                      No Races on this Filter
                    </p>
                  ) : (
                    <RacesList {...{ useContext }} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <div className="h-[5rem]"></div>
    </>
  );
};

const RacesFullLobbyView = () => {
  const rcon = useRacesContext();

  const {
    showmybikes,
    set_showmybikes,
    showmyraces,
    set_showmyraces,
    smshowfilters,
    set_smshowfilters,
  } = rcon;

  const centermainref = useRef(null);
  const [show_scrtopbtn, set_show_scrtopbtn] = useState(false);
  const centermain_scroll = () => {
    // console.log("centermain", centermainref.current);
    if (nils(centermainref.current)) return;
    centermainref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const el = centermainref.current;
    if (nils(el)) return;
    let list = el.addEventListener("scroll", () => {
      if (el.scrollTop > 100) set_show_scrtopbtn(true);
      else set_show_scrtopbtn(false);
    });
    return () => {
      el.removeEventListener("scroll", list);
    };
  }, [centermainref.current]);

  return (
    <>
      <div className="small-screen xs:block lg:hidden">
        <div className="h-page relative">
          <RacesSection />

          <ErrorBoundary>
            <SMFiltSection
              {...{
                show: smshowfilters,
                open: () => set_smshowfilters(true),
                close: () => set_smshowfilters(false),
              }}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <SMMyRaces
              {...{
                show: showmyraces,
                open: () => set_showmyraces(true),
                close: () => set_showmyraces(false),
              }}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <SMMyBikes
              {...{
                show: showmybikes,
                open: () => set_showmybikes(true),
                close: () => set_showmybikes(false),
              }}
            />
          </ErrorBoundary>

          <div className="fixed bottom-0 h-[4rem] left-0 w-full bg-r2dark z-30">
            <div className="flex flex-row justify-evenly w-full">
              {[
                ["Filters", smshowfilters, () => set_smshowfilters(true)],
                ["My Races", showmyraces, () => set_showmyraces(true)],
                ["My Bikes", showmybikes, () => set_showmybikes(true)],
              ].map((e) => {
                let [txt, active, click] = e;
                return (
                  <Tag
                    onClick={click}
                    className={twMerge(
                      `small-rsnav-link-${_.snakeCase(txt)}`,
                      "text-slate-200 flex-1 text-center transform duration-300",
                      active ? "text-acc0" : "",
                    )}
                  >
                    <span className="text-[0.8rem] font-digi">{txt}</span>
                  </Tag>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="large-screen xs:hidden lg:grid grid-cols-12">
        <div className="col-span-3 w-full h-page resp-p-4 box-border">
          <LeftSidebar />
        </div>
        <div
          ref={centermainref}
          className="col-span-6 w-full h-page resp-p-4 box-border relative"
        >
          <div class="relative">
            {show_scrtopbtn && (
              <div
                onClick={() => centermain_scroll()}
                class={twMerge(
                  "fixed cursor-pointer right-[30%] bottom-[2rem] ",
                  "p-[1.5rem] z-[40] bg-black fc-cc rounded-full shadow-md shadow-acc0/30",
                )}
              >
                <FontAwesomeIcon className="" icon={faArrowUp} />
              </div>
            )}
            <InfoBanner />
            <RacesMidBreakBanner />
            <div class="my-[1rem] w-[80%] mx-auto">
              <RoadmapBackLink override_show={true} />
            </div>
            <RacesFiltSection />
            <RacesSection />
          </div>
        </div>
        <div className="col-span-3 w-full h-page resp-p-4 box-border">
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export const RacesContextWrapper = ({ children, ...props }) => {
  const appcon = useAppContext();
  const { s_acc_config, g_acc_config, hids: vhids } = useAccountContext();
  const { psearch, upd_psearch } = appcon;

  const sel_init_c = () => {
    if (!nils(psearch.falias)) {
      if (psearch.falias == "roadmap_free") return 90;
      if (psearch.falias == "roadmap_trialrace") return 80;
      if (psearch.falias == "roadmap_paidrace") return 80;
    }
    let sc = parseInt(psearch.c);
    let cc = parseInt(g_acc_config(`open_races.basefilt.c`));
    if (sc) return sc;
    if (cc) return cc;
    return 80;
  };

  // Filters
  const gen_basefilt = ({ c, psearch }) => {
    if (!nils(psearch.falias)) {
      if (psearch.falias == "roadmap_free") {
        return {
          class: [90],
          eventtags: ["Trainer"],
        };
      } else if (psearch.falias == "roadmap_trialrace") {
        return {
          class: [90],
          eventtags: ["Trial"],
        };
      } else if (psearch.falias == "roadmap_paidrace") {
        return {
          class: [90],
        };
      }
    }
    if (nils(c)) {
      return g_acc_config("open_races.basefilt.c70", {});
    } else {
      let f = psearch.f;
      f = base64_to_json(f);
      f = f ?? {};
      if (_.isEmpty(f)) {
        let cac_f = g_acc_config(`open_races.basefilt.c${c}`, {});
        f = cac_f;
      }
      return f;
    }
    return {};
  };

  const [paused, set_paused] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      set_paused(false);
    }, 3 * 1e3);
  }, []);

  const gen_init_allvals = () => {
    // console.log("gen_init_allvals running", psearch.falias);
    let c = sel_init_c();
    let f = gen_basefilt({ c, psearch });
    s_acc_config(`open_races.basefilt.c`, c);
    s_acc_config(`open_races.basefilt.c${c}`, f);
    // console.log("init:save_cache", f);
    return [c, f, f];
  };
  const [init_c, init_basefilt, init_filt] = useMemo(
    () => gen_init_allvals(),
    [],
  );

  const [c, set_c] = useState(init_c);
  const basefilt = useMemo(
    () => gen_basefilt({ c, psearch }),
    [jstr(psearch), c],
  );
  const [filt, set_filt] = useState(init_filt);

  useEffect(() => {
    if (paused) return;
    let cac_f = g_acc_config(`open_races.basefilt.c${c}`, {});
    // console.log("on class change cache:", cac_f);
    set_filt(cac_f);
  }, [c]);

  useEffect(() => {
    console.log("races", { c });
    s_acc_config(`open_races.basefilt.c`, c);

    let cac_f = g_acc_config(`open_races.basefilt.c${c}`, {});
    set_filt(cac_f);
  }, [c]);

  const valfilt = useMemo(() => {
    let valfilt = _.cloneDeep(filt);
    console.log(c, "init valfilt", valfilt);
    s_acc_config(`open_races.basefilt.c${c}`, valfilt);
    valfilt.class = [parseInt(c)];
    let red_rvid = `open_races${c}.rvmode`;
    if (_.isEmpty(valfilt.rvmode)) {
      let cac = jparse(localStorage.getItem(red_rvid));
      if (!_.isEmpty(cac)) valfilt.rvmode = cac;
      else valfilt.rvmode = ["bike"];
    }
    if (!_.isEmpty(valfilt.rvmode)) {
      localStorage.setItem(red_rvid, jstr(valfilt.rvmode));
    }

    return valfilt;
  }, [jstr(filt), c]);

  const [peek, set_peek] = useState(basefilt?.peek ?? null);
  const [toburn, set_toburn] = useState(basefilt?.toburn ?? false);
  const [beta, set_beta] = useState(basefilt?.beta ?? false);
  const [giveeacards, set_giveeacards] = useState(
    basefilt?.giveeacards ?? false,
  );
  const [showtpraces, set_showtpraces] = useState(
    basefilt?.showtpraces ?? false,
  );

  const clear_filt = () => {
    let f = gen_filters_from_valob(filt, {});
    set_filt(f);
  };

  const q_open_minis_enabled = !nils(c);
  const [qo_tokpri, qo_open_races] = useQueries([
    q_token_prices(),
    q_open_races(
      {
        class: valfilt.class,
        cb: valfilt.cb,
        rgate: valfilt.rgate,
        format: valfilt.format,
        rvmode: valfilt.rvmode,
        ...(beta
          ? {
              version: ["cv2"],
            }
          : {}),
      },
      { enabled: q_open_minis_enabled },
    ),
  ]);

  const { tok_to_usd_val, usd_to_tok_val } = useAppContext();

  const [races, races_n] = useMemo(() => {
    const tok = getv(qo_tokpri, "data.result") || {};
    let ethusd = tok.ethusd ?? 0;
    let rs = getv(qo_open_races.data, "result");
    if (_.isEmpty(rs)) return [[], 0];
    let races = [];
    for (let r of rs) {
      let race = r;
      if (race) {
        race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
        race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
        // console.log(race);
      }
      races.push(race);
    }
    races = _.compact(races);
    races = _.compact(races);
    return [races, races.length];
  }, [qo_open_races.dataUpdatedAt, jstr(valfilt), qo_tokpri.dataUpdatedAt]);

  const [filtraces, filtraces_n] = useMemo(() => {
    // console.log("2.filtraces");
    let rs = filt_ar_using_valfilt({
      ar: races,
      valfilt,
      filtmap: {
        rgate: { path: "rgate" },
        payout: { path: "payout" },
        format: { path: "format" },
        eventtags: {
          path: (r, f, filters) => {
            if (peek == "noextcri") {
              return _.isEmpty(r.eventtags);
            }

            if (_.isEmpty(f)) return true;

            let hasall = true;
            for (let e of f) {
              if (!r.eventtags.includes(e)) {
                hasall = false;
              }
            }
            return hasall;
          },
        },
      },
    });
    rs = rs.filter((r) => {
      let evs = r.eventtags || [];
      let istp = evs.includes("Trail Points");
      let isburn = evs.includes("Burn");
      let iscards = r.giveeacards >= 1;
      // return true;
      return istp == showtpraces && toburn == isburn && iscards == giveeacards;
    });
    return [rs, rs.length];
  }, [jstr(races), jstr(valfilt), peek, showtpraces, toburn, giveeacards]);

  const [selrace, set_selrace] = useState(psearch.rid);

  const [smshowfilters, set_smshowfilters] = useState(false);
  const [showmyraces, set_showmyraces] = useState(
    psearch.showmyraces == "true",
  );
  const [showmybikes, set_showmybikes] = useState(false);

  const rem = useMemo(() => {
    return;
    let rem = { c };
    rem.f = json_to_base64(valfilt);
    rem.rid = selrace;
    rem.peek = peek;
    rem.toburn = toburn;
    rem.showmyraces = showmyraces ? "true" : "false";
    upd_psearch(rem);
    return rem;
  }, [jstr(valfilt), selrace, peek, c, showmyraces]);

  const selrace_dets = useMemo(() => {
    return _.find(filtraces, (r) => r.rid == selrace);
  }, [selrace, jstr(filtraces)]);
  useEffect(() => {
    if (!selrace) return;
    let r = _.find(filtraces, (r) => r.rid == selrace);
    if (!r) set_selrace(undefined);
  }, [selrace]);

  const rcon = {
    c,
    set_c,

    filt,
    set_filt,
    clear_filt,

    peek,
    set_peek,
    toburn,
    set_toburn,
    beta,
    set_beta,
    giveeacards,
    set_giveeacards,
    showtpraces,
    set_showtpraces,

    valfilt,

    q_open_minis_enabled,
    qo_open_minis: qo_open_races,
    qo_open_races,

    races,
    races_n,
    filtraces,
    filtraces_n,

    selrace,
    set_selrace,
    selrace_dets,

    smshowfilters,
    set_smshowfilters,
    showmyraces,
    set_showmyraces,
    showmybikes,
    set_showmybikes,
  };
  // useEffect(() => { console.log("rcon", rcon, qo_open_races); }, [jstr(rcon)]);

  return (
    <>
      <RacesContext.Provider value={rcon}>
        {React.cloneElement(children, { rcon })}
      </RacesContext.Provider>
    </>
  );
};

function Races() {
  // const appcon = useAppContext();
  // const { s_acc_config, g_acc_config, hids: vhids } = useAccountContext();
  // const { psearch, upd_psearch } = appcon;
  //
  // const sel_init_c = () => {
  //   if (!nils(psearch.falias)) {
  //     if (psearch.falias == "roadmap_free") return 90;
  //     if (psearch.falias == "roadmap_trialrace") return 80;
  //     if (psearch.falias == "roadmap_paidrace") return 80;
  //   }
  //   let sc = parseInt(psearch.c);
  //   let cc = parseInt(g_acc_config(`open_races.basefilt.c`));
  //   if (sc) return sc;
  //   if (cc) return cc;
  //   return 80;
  // };
  //
  // // Filters
  // const gen_basefilt = ({ c, psearch }) => {
  //   if (!nils(psearch.falias)) {
  //     if (psearch.falias == "roadmap_free") {
  //       return {
  //         class: [90],
  //         eventtags: ["Trainer"],
  //       };
  //     } else if (psearch.falias == "roadmap_trialrace") {
  //       return {
  //         class: [90],
  //         eventtags: ["Trial"],
  //       };
  //     } else if (psearch.falias == "roadmap_paidrace") {
  //       return {
  //         class: [90],
  //       };
  //     }
  //   }
  //   if (nils(c)) {
  //     return g_acc_config("open_races.basefilt.c70", {});
  //   } else {
  //     let f = psearch.f;
  //     f = base64_to_json(f);
  //     f = f ?? {};
  //     if (_.isEmpty(f)) {
  //       let cac_f = g_acc_config(`open_races.basefilt.c${c}`, {});
  //       f = cac_f;
  //     }
  //     return f;
  //   }
  //   return {};
  // };
  //
  // const [paused, set_paused] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     set_paused(false);
  //   }, 3 * 1e3);
  // }, []);
  //
  // const gen_init_allvals = () => {
  //   // console.log("gen_init_allvals running", psearch.falias);
  //   let c = sel_init_c();
  //   let f = gen_basefilt({ c, psearch });
  //   s_acc_config(`open_races.basefilt.c`, c);
  //   s_acc_config(`open_races.basefilt.c${c}`, f);
  //   // console.log("init:save_cache", f);
  //   return [c, f, f];
  // };
  // const [init_c, init_basefilt, init_filt] = useMemo(
  //   () => gen_init_allvals(),
  //   [],
  // );
  //
  // const [c, set_c] = useState(init_c);
  // const basefilt = useMemo(
  //   () => gen_basefilt({ c, psearch }),
  //   [jstr(psearch), c],
  // );
  // const [filt, set_filt] = useState(init_filt);
  //
  // useEffect(() => {
  //   if (paused) return;
  //   let cac_f = g_acc_config(`open_races.basefilt.c${c}`, {});
  //   // console.log("on class change cache:", cac_f);
  //   set_filt(cac_f);
  // }, [c]);
  //
  // const valfilt = useMemo(() => {
  //   let valfilt = _.cloneDeep(filt);
  //   // console.log("update valfilt", { c }, valfilt);
  //   s_acc_config(`open_races.basefilt.c${c}`, valfilt);
  //   valfilt.class = [parseInt(c)];
  //   return valfilt;
  // }, [jstr(filt), c]);
  //
  // const [peek, set_peek] = useState(basefilt?.peek ?? null);
  // const [toburn, set_toburn] = useState(basefilt?.toburn ?? false);
  // const [giveeacards, set_giveeacards] = useState(
  //   basefilt?.giveeacards ?? false,
  // );
  // const [showtpraces, set_showtpraces] = useState(
  //   basefilt?.showtpraces ?? false,
  // );
  //
  // const clear_filt = () => {
  //   let f = gen_filters_from_valob(filt, {});
  //   set_filt(f);
  // };
  //
  // const q_open_minis_enabled = !nils(c) && !nils(valfilt.rvmode);
  // const [qo_tokpri, qo_open_races] = useQueries([
  //   q_token_prices(),
  //   q_open_races(
  //     {
  //       class: valfilt.class,
  //       cb: valfilt.cb,
  //       rgate: valfilt.rgate,
  //       format: valfilt.format,
  //       rvmode: valfilt.rvmode,
  //       version: ["cv2"],
  //     },
  //     { enabled: q_open_minis_enabled },
  //   ),
  // ]);
  //
  // const { tok_to_usd_val, usd_to_tok_val } = useAppContext();
  //
  // const [races, races_n] = useMemo(() => {
  //   const tok = getv(qo_tokpri, "data.result") || {};
  //   let ethusd = tok.ethusd ?? 0;
  //   let rs = getv(qo_open_races.data, "result");
  //   if (_.isEmpty(rs)) return [[], 0];
  //   let races = [];
  //   for (let r of rs) {
  //     let race = r;
  //     if (race) {
  //       race.fee_usd = tok_to_usd_val(race.fee, race.paytoken);
  //       race.prize_usd = tok_to_usd_val(race.prize, race.paytoken);
  //       // console.log(race);
  //     }
  //     races.push(race);
  //   }
  //   races = _.compact(races);
  //   races = _.compact(races);
  //   return [races, races.length];
  // }, [qo_open_races.dataUpdatedAt, jstr(valfilt), qo_tokpri.dataUpdatedAt]);
  //
  // const [filtraces, filtraces_n] = useMemo(() => {
  //   // console.log("1.filtraces", { showtpraces, toburn, peek });
  //   let rs = filt_ar_using_valfilt({
  //     ar: races,
  //     valfilt,
  //     filtmap: {
  //       rgate: { path: "rgate" },
  //       payout: { path: "payout" },
  //       format: { path: "format" },
  //       eventtags: {
  //         path: (r, f, filters) => {
  //           // console.log("filt:eventtags", { showtpraces, toburn, peek });
  //
  //           if (peek == "noextcri") {
  //             return _.isEmpty(r.eventtags);
  //           }
  //
  //           if (_.isEmpty(f)) return true;
  //           let hasall = true;
  //           for (let e of f) {
  //             if (!r.eventtags.includes(e)) {
  //               hasall = false;
  //             }
  //           }
  //           return hasall;
  //         },
  //       },
  //     },
  //   });
  //   rs = rs.filter((r) => {
  //     let evs = r.eventtags || [];
  //     let istp = evs.includes("Trail Points");
  //     let isburn = evs.includes("Burn");
  //     let iscards = r.giveeacards >= 1;
  //     // return true;
  //     return istp == showtpraces && toburn == isburn && iscards == giveeacards;
  //   });
  //   return [rs, rs.length];
  // }, [jstr(races), jstr(valfilt), peek, showtpraces, toburn, giveeacards]);
  //
  // const [selrace, set_selrace] = useState(psearch.rid);
  //
  // const [smshowfilters, set_smshowfilters] = useState(false);
  // const [showmyraces, set_showmyraces] = useState(
  //   psearch.showmyraces == "true",
  // );
  // const [showmybikes, set_showmybikes] = useState(false);
  //
  // const rem = useMemo(() => {
  //   return;
  //   let rem = { c };
  //   rem.f = json_to_base64(valfilt);
  //   rem.rid = selrace;
  //   rem.peek = peek;
  //   rem.toburn = toburn;
  //   rem.showmyraces = showmyraces ? "true" : "false";
  //   upd_psearch(rem);
  //   return rem;
  // }, [jstr(valfilt), selrace, peek, c, showmyraces]);
  //
  // const selrace_dets = useMemo(() => {
  //   return _.find(filtraces, (r) => r.rid == selrace);
  // }, [selrace, jstr(filtraces)]);
  // useEffect(() => {
  //   if (!selrace) return;
  //   let r = _.find(filtraces, (r) => r.rid == selrace);
  //   if (!r) set_selrace(undefined);
  // }, [selrace]);
  //
  // const rcon = {
  //   c,
  //   set_c,
  //
  //   filt,
  //   set_filt,
  //   clear_filt,
  //
  //   peek,
  //   set_peek,
  //   toburn,
  //   set_toburn,
  //   giveeacards,
  //   set_giveeacards,
  //   showtpraces,
  //   set_showtpraces,
  //
  //   valfilt,
  //
  //   q_open_minis_enabled,
  //   qo_open_minis: qo_open_races,
  //
  //   races,
  //   races_n,
  //   filtraces,
  //   filtraces_n,
  //
  //   selrace,
  //   set_selrace,
  //   selrace_dets,
  // };
  //
  // const pagetitle = useMemo(() => {
  //   return `Races | FBike DNA`;
  // }, []);
  //
  // useEffect(() => {}, [jstr({ showmyraces })]);
  //
  // const centermainref = useRef(null);
  // const [show_scrtopbtn, set_show_scrtopbtn] = useState(false);
  // const centermain_scroll = () => {
  //   // console.log("centermain", centermainref.current);
  //   if (nils(centermainref.current)) return;
  //   centermainref.current.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  // useEffect(() => {
  //   const el = centermainref.current;
  //   if (nils(el)) return;
  //   let list = el.addEventListener("scroll", () => {
  //     if (el.scrollTop > 100) set_show_scrtopbtn(true);
  //     else set_show_scrtopbtn(false);
  //   });
  //   return () => {
  //     el.removeEventListener("scroll", list);
  //   };
  // }, [centermainref.current]);

  return (
    <>
      <Helmet>
        <title>{"Races | FBike DNA"}</title>
      </Helmet>
      <RacesContextWrapper>
        <RacesFullLobbyView />
      </RacesContextWrapper>
    </>
  );
}

export default Races;
